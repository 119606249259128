@import "theme.scss";
@import "theme_variables.scss";
@import "~ngx-drag-to-select/scss/ngx-drag-to-select";

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.no-data {
    font-size: larger;
    margin: 2em;
}

// ngx-file-drop
ngx-file-drop {
    .file-drop-zone {
        border: 2px dotted $primary;
        border-radius: 6px;
        margin: auto;
        height: 143px;
    
        .file-drop-content {
            .file-drop-tmp {
                margin: 2em;
            }
        }
    }
}

// ngx-drag-to-select
.dts-select-container:not(.dts-custom) .dts-select-item.dts-range-start, .dts-select-item-no-style {
    border: 0;
}